/* @mixin vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
} */

@font-face {
	font-family: "averta std";
	font-weight: 400;
	style: normal;
	src: url("./fonts/AvertaDemoPECuttedDemo-Regular.otf");
}

@font-face {
	font-family: "averta std bold";
	font-style: bold;
	font-weight: 400;
	src: url("./fonts/AvertaDemoPE-ExtraBold.otf");
}

/*
@font-face {
  font-family: 'averta std';
  src: local('averta std'), url(./fonts/Rajdhani/Rajdhani-Regular.ttf) format('truetype');
} */

html {
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
	width: 100%;
}

img {
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
}

body {
	height: 100%;
	width: 100%;
	color: #000000;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	line-height: 1.43;
	letter-spacing: 0.01071em;
	overflow: hidden;
	margin: 0;
	font-family: "averta std", sans-serif;
	line-height: 21.6px;
}

body a:active,
body a:-webkit-any-link:active {
	color: var(--active-brand-color);
}

.App {
	width: 100%;
	height: 100%;
	max-width: 100%;
	max-height: 100%;
}

.show-container {
	-webkit-animation: scale-in-br 0.06s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: scale-in-br 0.06s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.hide-container {
	-webkit-animation: scale-in-br 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both;
	animation: scale-in-br 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both;
}

@keyframes scale-in-br {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
		-webkit-transform-origin: 100% 100%;
		transform-origin: 100% 100%;
		opacity: 1;
	}

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: 100% 100%;
		transform-origin: 100% 100%;
		opacity: 1;
	}
}

@keyframes shadowPulse {
	33% {
		background: #fff;
		box-shadow: -12px 0 var(--active-brand-color), 12px 0 #fff;
	}

	66% {
		background: var(--active-brand-color);
		box-shadow: -12px 0 #fff, 12px 0 #fff;
	}

	100% {
		background: var(--bot-msg-bg);
		box-shadow: -12px 0 #fff, 12px 0 var(--active-brand-color);
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

/* Restyle Default Scrollbar */

/* Firefox */

* {
	scrollbar-width: thin;
	scrollbar-color: var(--color-light-grey) transparent;
	scroll-behavior: auto;
}

/* Chrome, Edge, and Safari */

*::-webkit-scrollbar {
	display: block;
	width: 8px;
	height: 8px;
	padding: 10px;
	scroll-behavior: auto;
}

*::-webkit-scrollbar-track {
	margin: 0 5px;
}

*::-webkit-scrollbar-thumb {
	background-color: var(--color-light-grey);

	border-radius: 29px;
	border-right: none;
	border-left: none;
}

*::-webkit-scrollbar-track-piece:end {
	border-bottom-left-radius: 29px;
	border-bottom-right-radius: 29px;
	background: var(--color-alt-grey);
	margin-bottom: 10px;
}

*::-webkit-scrollbar-track-piece:start {
	border-top-left-radius: 29px;
	border-top-right-radius: 29px;
	background: var(--color-alt-grey);
	margin-top: 10px;
}

/* Add for test */
/* html,
body {
	-webkit-overflow-scrolling: touch !important;
	overflow: auto !important;
} */
