body {
  --default-font-size: 16px;
  --small-font-size: 14px;
  --large-font-size: 18px;

  --default-brand-color: #014ee2;
  --default-text-color: #ffffff;
  --default-avatar-url: url("../images/robot.svg");
  --default-user-msg-bg: #0045ca;
  --default-high-contrast: #03266d;

  --color-light-grey: rgba(109, 114, 120, 0.5);
  --color-dark-grey: #37474f;
  --color-alt-grey: #eeeeee;
  --monochrome-text: #5b5b5b;

  --user-msg-txt: #ffffff;
  --bot-msg-txt: #000000;
  --bot-msg-bg: rgba(118, 136, 144, 0.1);

  --custom-brand-color: var(--default-brand-color);
  --custom-accent-color: var(--default-user-msg-bg);
  --custom-contrast-color: var(--default-high-contrast);
  --custom-text-color: var(--default-text-color);
  --custom-avatar-url: url("https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/batman_hero_avatar_comics-512.png");

  --active-brand-color: var(--default-brand-color);
  --active-text-color: var(--default-text-color);
  --active-avatar-url: var(--default-avatar-url);
  --active-font-size: var(--default-font-size);
  --active-high-contrast: var(--default-high-contrast);
  --active-msg-bg: var(--default-user-msg-bg);

  --bot-status-connected: #2eff68;
  --bot-status-disconnected: #dc3545;
  --bot-status: var(--bot-status-disconnected);

  font-size: var(--active-font-size);
}
